/* 
html {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }
}
 */
a {
  text-decoration: none;
  color: inherit;
}


pre {
  width: 100% !important;
  padding: 24px !important;
  align-items: left !important;
  border-radius: 24px !important;
  overflow: scroll !important;
}
code {
  overflow: scroll !important;
}

body::-webkit-scrollbar {
  width: 1em;
  scrollbar-gutter: stable;
  scrollbar-width: none;
}


::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: #4b4b4b;
    scrollbar-width: none;
}

body {
  scrollbar-width: none !important;
}